import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Helmet from 'react-helmet';
import { Query, Mutation, MutationFn, withApollo, WithApolloClient } from 'react-apollo';

import { url, routes, path } from 'inkp-routes/public';
import { isUserRegistered } from 'inkp-user-sdk/user';
import Alert from 'inkp-components/dist/Components/Alert';
import Input from 'inkp-components/dist/Components/Input';
import Loading from 'inkp-components/dist/Components/Loading';

import {
  getCurrentUser,
} from '../../util/login';
import {
  GET_ORDER_BY_ID_AND_EMAIL_QUERY,
} from '../../util/orders';
import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';

class TrackOrder extends React.Component<RouteComponentProps> {

  state = {
    fields: {
      email: '',
      orderId: '',
    },
    error: '',
    loading: false,
  }

  updateField = (fieldName: string, value: string) => {
    if (fieldName === 'email' || fieldName === 'orderId') {
      const sanitizedValue = value && value.trim().toLowerCase();
      const fields = Object.assign({}, this.state.fields, {[fieldName]: sanitizedValue});
      this.setState({ fields });
    }
  }

  submit = () => {
    const { orderId, email } = this.state.fields;
    if (!orderId || !email) return;

    const orderPromise = this.props.client.query({
      query: GET_ORDER_BY_ID_AND_EMAIL_QUERY,
      variables: {
        id: orderId,
        email: email,
      },
    });

    this.setState({loading: true});
    orderPromise.then((order) => {
      this.setState({loading: false});
      this.props.history.push(path(routes.app.order.getById, {orderId, email}));
    }, (err) => {
      this.setState({loading: false});
      this.setState({ error: `Could not find order ${orderId}` });
    })
  }

  closeAlert = () => () => {
    this.setState({ error: '' });
  }

  componentDidMount() {
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);

    const user = getCurrentUser(this.props.client.cache);
    if (user && isUserRegistered(user)) {
      this.props.history.push(`${routes.app.account.myAccount}?tab=Orders`);
    }
  }

  render() {

    return (
      <React.Fragment>
        <Helmet>
          <title>Inkpop - Track Your Order</title>
        </Helmet>
        <div className="TrackOrder flex flex-col w-full px-1 md:w-container md:mx-auto">
          <div className="w-full py-1 ta-left">
            <Link to={routes.app.base} className="color-blue fs-md fw-bold">
              <i className="d-ib mdi mdi-chevron-left color-blue fs-xl" />
              <h3 className="d-ib fs-md align-text-bottom">Back to Home</h3>
            </Link>
          </div>
          {this.state.error &&
            <Alert
              alerts={[
                {
                  type: 'error',
                  title: 'Failed to lookup order',
                  content: this.state.error,
                  onClose: this.closeAlert(),
                },
              ]}
            />
          }
          <div className="w-full py-1 md:w-1/2 md:mx-auto">
            <h1 className="fw-extra-bold color-navy fs-lg">Track Your Order</h1>
          </div>
          <form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              this.submit();
            }}
          >
            <div className="w-full mb-1p5 md:w-1/2 md:mx-auto">
              <Input
                type="boxed"
                inputType="email"
                label="Email"
                placeholder="Email"
                error={false}
                helper=""
                icon=""
                rightIcon=""
                autoFocus={true}
                clearable={false}
                onFocus={() => {}}
                onBlur={() => {}}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  this.updateField('email', event.target.value);
                }}
                disabled={false}
                removeInnerLabel={false}
                value={this.state.fields.email}
              />
            </div>
            <div className="w-full mb-1p5 md:w-1/2 md:mx-auto">
              <Input
                type="boxed"
                label="Order Id"
                placeholder="Order Id"
                inputType="text"
                icon=""
                rightIcon=""
                autoFocus={false}
                clearable={false}
                onFocus={() => {}}
                onBlur={() => {}}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  this.updateField('orderId', event.target.value);
                }}
                disabled={false}
                removeInnerLabel={false}
                value={this.state.fields.orderId}
              />
            </div>
            <div
              className="w-full ta-right md:w-1/2 md:mx-auto"
              style={{ marginBottom: '112px' }}
            >
              <button
                type="submit"
                className="d-ib select-none outline-0:focus fs-md fw-bold px-1p5 py-p75 br-full color-white bgc-primary cursor-pointer"
                disabled={!this.state.fields.orderId || !this.state.fields.email}
              >
                <div className="w-6">
                  {this.state.loading ?
                    <Loading
                      size="small"
                      textClassName="d-n"
                      paddingClassName="p-0"
                    />
                    :
                    'Track Order'
                  }
                </div>
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

export default withApollo(TrackOrder);
